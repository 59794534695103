import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {EventEmitter, Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {JwtHelperService} from '@auth0/angular-jwt';
import {BehaviorSubject, Observable, firstValueFrom} from 'rxjs';
import {AccountValidationModel} from '../../authentication/models/account-validation.model';
import {UserCredentialsModel} from '../../authentication/models/user-credentials.model';
import {commonProperties} from '../../../assets/environments/environment.common';
import {environment} from '../../../assets/environments/environment';
import { EmployeeSummary } from '../../authentication/models/employee-summary.model';


@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private usernameSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  private pictureSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private isCguValidated: boolean|undefined = undefined;
  public checkCguEvent = new EventEmitter();

  constructor(private jwtHelper: JwtHelperService,
              private http_client: HttpClient,
              private router: Router) {
  }

  /**
   * This method returns a boolean if the user is connected or not.
   */
  isAuthenticated() {
    return !this.jwtHelper.isTokenExpired();
  }

  /**
   * This method delete the access_token from the localStorage and route the app to the login page
   */
  logout() {
    const token = localStorage.getItem(commonProperties.token);
    this.isCguValidated = undefined;
    localStorage.clear();
    this.http_client.post(environment.api_root + commonProperties.logout, {token})
      .subscribe(() => {
        this.router.navigate(['/login']);
      }, () => {
        this.router.navigate(['/login']);
      });
  }

  /**
   * Logs a user by sending the user credentials to the back office API to connect him
   * @param userCredentials request body holding the username, password and the app type
   */
  login(userCredentials: UserCredentialsModel) {
    // console.log('your user inputs:', userCredentials);
    return this.http_client.post(environment.api_root + commonProperties.login, userCredentials);

  }

  getSelfEmployeeInformations(): Observable<EmployeeSummary> {
    return this.http_client.get<EmployeeSummary>(environment.api_root + commonProperties.employeeSelf);
  }


  getCurrentCompanyId() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token)).tenantId;
    }
   }

  refreshToken(entityId: number|undefined = null) {
    const token = localStorage.getItem(commonProperties.token);
    const refreshTokenBody = {
      token,
      entityId
    };
    return this.http_client.post(environment.api_root + commonProperties.refreshToken, refreshTokenBody);

  }

  /**
   * this method store the access_token in localstorage and route the app to the dashboard page
   * @param res response from the API
   */
  storeToken(res: any) {
    localStorage.setItem(commonProperties.token, res.access_token);
    localStorage.setItem(commonProperties.tokenExpiration, res.expires_in);
  }

  /**
   * Request a new password for the account related to the email specified
   * @param email user's email
   */
  forgetPassword(email: string) {
    const params = new HttpParams().set('email', email);
    return this.http_client.get(environment.api_root + commonProperties.forgetPassword, {params});
  }

  /**
   * Validate an account to make it active by specifying the token received by email and the password account
   * @param token activation token
   * @param password password account
   */
  validateAccount(token: string, password: string) {
    const params = new HttpParams().set('activationKey', token);
    return this.http_client.post(environment.api_root + commonProperties.activateAccount, new AccountValidationModel(password), {params});
  }

  /**
   * Reset a password by specifying the token received by email and the new password
   * @param token reset password token
   * @param password new password to set for the account
   */
  resetPassword(token: string, password: string) {
    const params = new HttpParams().set('resetKey', token);
    return this.http_client.post(environment.api_root + commonProperties.resetPassword, new AccountValidationModel(password), {params});
  }

  /**
   *
   */
  getRole() {
    if (this.isAuthenticated()) {
      // console.log('user roles', this.jwtHelper.decodeToken(localStorage.getItem(environment.token))['authorities']);
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token)).authorities;
    } else {
      // console.log('User not authenticated.');
    }
  }

  getEmployeeAccount(employeeId: string) {
    const params = new HttpParams().set('employeeId', employeeId);
    return this.http_client.get(environment.api_root + commonProperties.accountByEmployeeId, {params: params});
  }

  getCompanyStatus() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token)).enterpriseStatus;
    } else {
      return null;
    }
  }

  getMyEmployeeAccount() {
    const params = new HttpParams().set('employeeId', this.decodeToken('employeeId'));
    return this.http_client.get(environment.api_root + commonProperties.accountByEmployeeId, {params: params});
  }

  setUsername(username: string) {
    this.usernameSubject.next(username);
  }

  getUsername() {
    if (this.isAuthenticated() && !this.usernameSubject.value) {
      this.setUsername(this.decodeToken('name'));
    }
    return this.usernameSubject.asObservable();
  }

  setPicture(photo: any) {
    this.pictureSubject.next(photo);
  }

  getPicture() {
    return this.pictureSubject.asObservable();
  }

  getEmployeeId() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token)).employeeId;
    } else {
      return null;
    }
  }

  // Decodes token so that the payload can be read
  decodeToken(key?: string): string {
    const token = this.jwtHelper.decodeToken(localStorage.getItem('access_token'));
    if (key) {
      return token[key];
    } else {
      return token;
    }
  }

  updateUserLang(body: any) {
    return this.http_client.patch(environment.api_root + commonProperties.lang.replace(':employeeId', this.decodeToken('userId')), body);
  }

  getUserLang() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token)).lang;
    } else {
      return null;
    }
  }

  getUserCompanyId() {
    if (this.isAuthenticated()) {
      return this.jwtHelper.decodeToken(localStorage.getItem(commonProperties.token)).tenantId;
    } else {
      return null;
    }
  }


  getCgu(): Observable<any> {
    const url = environment.api_root + commonProperties.cgu;
    const headers = new HttpHeaders();
    const options = {
      headers: headers.set('Accept', 'application/pdf'),
      responseType: 'blob' as 'json'
    };
    return this.http_client.get<Blob>(url, options);
  }

  async getCguStatus(): Promise<boolean>  {
    if (this.isCguValidated === undefined) {
      const url = environment.api_root + commonProperties.cguUser;
      this.isCguValidated = await firstValueFrom(this.http_client.get<boolean>(url));
    }
    return this.isCguValidated;
  }

  acceptCgu(): Observable<boolean>  {
    this.isCguValidated = true;
    const url = environment.api_root + commonProperties.cguUser;
    const params = new HttpParams().set('accepted', true);

    return this.http_client.post<boolean>(url, null, {params});
  }
}
