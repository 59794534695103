import {Component, OnDestroy, OnInit} from '@angular/core';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {environment} from '../../../../assets/environments/environment';
import {AuthService} from '../../services/auth-service.service';
import {EmployeeService} from '../../../employee/services/employee.service';
import { CompanySummary } from '../../../authentication/models/company-summary.model';
import {faLock, faPowerOff, faUser} from '@fortawesome/pro-duotone-svg-icons';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  username: string;
  imageSrc: any;

  employeeEntities: CompanySummary[] = [];
  currentEntity: CompanySummary;

  protected readonly faUser = faUser;
  protected readonly faLock = faLock;
  protected readonly faPowerOff = faPowerOff;
  private subscriptions: Subscription[] = [];

  constructor(
    private authService: AuthService,
    private employeeService: EmployeeService,
    private logger: NGXLogger
  ) {}

  ngOnInit() {
    this.subscriptions.push(this.authService.getUsername().subscribe(value => {
      if (value) {
        this.username = value;
      }
    }));
    this.subscriptions.push(this.authService.getPicture().subscribe(value => {
      if (value) {
        this.imageSrc = value;
      } else {
        this.subscriptions.push(this.employeeService.getMyPicture().subscribe(
          (res: any) => {
            const reader = new FileReader();
            reader.addEventListener('load', () => {
              const imageSrc = reader.result as string;
              this.authService.setPicture(imageSrc);
            }, false);
            if (res) {
              reader.readAsDataURL(res);
            } else {
              this.imageSrc = null;
            }
          }, error => {
            this.logger.error(error.url, '- STATUS :', error.status);
          }
        ));
      }
    }));

        this.authService.getSelfEmployeeInformations().subscribe(employee => {
          this.employeeEntities = employee.companies;
          this.currentEntity = this.employeeEntities.find(c => c.id === this.authService.getCurrentCompanyId());
        });
  }

  logout() {
    this.authService.logout();
  }

  changeEntity(entity: CompanySummary) {
    this.authService.refreshToken(entity.id).subscribe(data => {
      this.authService.storeToken(data);
      location.reload();
    });
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }

  protected readonly environment = environment;
}
