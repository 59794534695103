import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {NGXLogger} from 'ngx-logger';
import {Subscription} from 'rxjs';
import {AlertService} from '../../core/services/alert.service';
import {AuthService} from '../../core/services/auth-service.service';
import {RoleService} from '../../core/services/role.service';
import {UserCredentialsModel} from '../models/user-credentials.model';
import {TranslateService} from '@ngx-translate/core';
import {TranslatorService} from '../../core/services/translator.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  loginForm: FormGroup;
  isLoggingIn = false;

  private subscriptions: Subscription[] = [];
  protected isModified: boolean = false;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private roleService: RoleService,
              private alertService: AlertService,
              private router: Router,
              private logger: NGXLogger,
              private translateService: TranslateService,
              private translatorService: TranslatorService) {

  }

  ngOnInit(): void {
    this.loginForm = this.fb.group({
      email: [null, [Validators.required, Validators.maxLength(50)]],
      password: [null, [Validators.required, Validators.maxLength(50)]]
    });
    this.loginForm.valueChanges.subscribe(value => {
      this.isModified = true;
    });
  }

  loginFormSubmit(): void {
    this.alertService.clear();
    this.isLoggingIn = true;
    this.isModified = false;
    const userCredentials = new UserCredentialsModel();
    userCredentials.email = this.loginForm.value.email;
    userCredentials.password = this.loginForm.value.password;
    this.subscriptions.push(this.authService.login(userCredentials).subscribe(
      (res: any) => {
        this.authService.storeToken(res);
        this.authService.setUsername(this.authService.decodeToken('name'));
        // user logged in, send event to check cgu
        this.authService.checkCguEvent.emit(null);
        if (this.authService.getRole() === 'ROLE_BUYER_ADMIN'
          && this.authService.getCompanyStatus() === 'IN_PROGRESS'
          || this.authService.getCompanyStatus() === 'SUBMITTED') {
          this.router.navigate(['my-company/update/legal-information']);
        } else {
          if (this.roleService.isRole(['ROLE_BUYER_ADMIN', 'ROLE_BUYER'])) {
            this.router.navigate(['dashboard']);
          } else {
            this.router.navigate(['dashboard', 'budget']);
          }
        }
        this.translatorService.changeLang(this.translatorService.getLanguage());
        this.isLoggingIn = false;
      },
      (error: any) => {
        this.logger.error(error.url, '- STATUS :', error.status);
        this.subscriptions.push(this.translateService.get(['authentication.login-ts.login-form-submit.error.bad_credentials',
        'authentication.login-ts.login-form-submit.error.account_blocked',
          'authentication.login-ts.login-form-submit.error.account_disabled',
          'authentication.login-ts.login-form-submit.error.account_not_activated']).subscribe((data: any) => {
          if (error.error.code === 'UAA_AUTH_UNAU_ERR_2' || error.error.code === 'UAA_RES_NOT_FND_ERR_4') {
            this.alertService.addAlert(data['authentication.login-ts.login-form-submit.error.bad_credentials'], 'warning');
          }
          if (error.error.code === 'UAA_AUTH_FORB_ERR_3') {
            this.alertService.addAlert(data['authentication.login-ts.login-form-submit.error.account_disabled'], 'warning');
          }
          if (error.error.code === 'UAA_AUTH_UNAU_ERR_5' ) {
            this.alertService.addAlert(data['authentication.login-ts.login-form-submit.error.account_blocked'], 'warning');
          }
          if (error.error.code === 'UAA_AUTH_FORB_ERR_4') {
            this.alertService.addAlert(data['authentication.login-ts.login-form-submit.error.account_not_activated'], 'warning');
          }
        }));
        this.isLoggingIn = false;
      })
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }
}
