<div class="container-fluid">
  <div class="row no-gutter">
    <div class="d-none d-md-flex col-md-4 col-lg-8 bg-image"></div>
    <div class="col-md-8 col-lg-4">
      <div class="login d-flex align-items-center py-5">
        <div class="container">
          <div class="row">
            <div class="col-md-9 col-lg-8 mx-auto">

              <div class="text-center brand-jumbotron">
                <img src="assets/brand/logo.svg" class="img-fluid iSupplier-logo"
                     alt="iSupplier logo"/>
                <h4 class="login-heading mb-4">{{'authentication.login-heading'|translate}}</h4>
              </div>

              <app-alert></app-alert>
              <form [formGroup]="loginForm" (ngSubmit)="loginFormSubmit()">

                <div class="form-group">
                  <label for="email" hidden></label>
                  <input class="form-control" id="email" type="text" placeholder="{{'global.common.email'|translate}}"
                         formControlName="email" />
                </div>

                <div class="form-group">
                  <label for="password" hidden></label>
                  <input class="form-control" id="password" type="password" placeholder="{{ 'authentication.login.form.password-placeholder'|translate}}"
                         formControlName="password"
                         autocomplete="new-password"/>
                </div>

                <button class="btn btn-primary btn-block font-weight-bold mb-2" type="submit"
                        [disabled]="!loginForm.valid || isLoggingIn || !isModified">
                  {{'authentication.login.form.button.login'|translate}}
                </button>
                <div class="text-center">
                  <a class="small" [routerLink]="['/forget-password']">{{'authentication.login.form.forgot-password'|translate}}</a>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
