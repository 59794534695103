import {AfterViewInit, Component, HostListener, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {AuthService} from './core/services/auth-service.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslatorService} from './core/services/translator.service';
import {commonProperties} from '../assets/environments/environment.common';
import {BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {ModalIdleWarningComponent} from './shared/components/modal-idle-warning/modal-idle-warning.component';
import {NavigationStart, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {CguModalComponent} from './shared/components/cgu-modal/cgu-modal.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit {
  title = 'SA-iSupplier';
  version: string;
  date: Date;
  modalRef?: BsModalRef;
  modalCguRef?: BsModalRef;
  @ViewChild('cgu', {read: TemplateRef}) cguModal: TemplateRef<any>;
  isAuthenticated: boolean = false;
  private isActive = false;

  constructor(public http: HttpClient,
              private authService: AuthService,
              private router: Router,
              private translateService: TranslateService,
              private translatorService: TranslatorService,
              private modalService: BsModalService) {
    this.translateService.setDefaultLang('GB');
    this.translatorService.changeLang(this.translatorService.getLanguage());
  }

  ngOnInit(): void {
    this.date = new Date();

    this.router.events.subscribe(event => {
      if (event instanceof NavigationStart) {
        this.isAuthenticated = this.authService.isAuthenticated();
      }
    });
    setInterval(() => {
      if (!localStorage.getItem(commonProperties.tokenExpiration)) {
        return;
      }
      const date = new Date(localStorage.getItem(commonProperties.tokenExpiration));
      const actual = new Date();
      const remainingMinutes = (date.getTime() - actual.getTime()) / 60 / 1000;
      if (this.isActive) {
        if (remainingMinutes <= 20) {
          this.refreshToken();
        }
      } else if (remainingMinutes <= 0) {
        this.router.navigate(['login']);
        if (this.modalRef) {
          this.modalRef.hide();
          this.modalRef = null;
        }
      } else if (remainingMinutes <= 2) {
        if (!this.modalRef) {
          this.modalRef = this.modalService.show(ModalIdleWarningComponent, {class: 'modal-lg modal-center'});
        }
      }
      this.isActive = false;
    }, 10000);
  }

  private refreshToken() {
    if (!this.authService.isAuthenticated()) {
      return;
    }
    this.authService.refreshToken().subscribe(data => {
      if (data) {
        this.authService.storeToken(data);
      }
    });
  }

  @HostListener('window:mousemove') refreshUserState() {
    if (!this.isActive) {
      this.isActive = true;
      if (this.modalRef) {
        if (this.authService.isAuthenticated()) {
          this.refreshToken();
        } else {
          this.router.navigate(['login']);
        }
        this.modalRef.hide();
        this.modalRef = null;
      }
    }
  }

  ngAfterViewInit() {
    this.authService.checkCguEvent.subscribe({next: () => this.checkCgu()});
    if (this.authService.isAuthenticated()) {
      setTimeout(() => this.checkCgu(), 2000);
    }
  }

  public showCGU(mandatory?: boolean) {
    if (mandatory) {
      this.modalCguRef = this.modalService.show(CguModalComponent, {backdrop: 'static', class: 'modal-lg', initialState: {cguToBeValidated: true}});
    } else {
      this.modalCguRef = this.modalService.show(CguModalComponent, {class: 'modal-lg', initialState: {cguToBeValidated: false}});
    }
  }

  checkCgu() {
    this.authService.getCguStatus().then(
      isValid => {
        if (!isValid) {
          this.showCGU(true);
        }
      }
    );
  }

}
