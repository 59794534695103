import { Component, OnDestroy } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { AuthService } from '../../../core/services/auth-service.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { ModalConfirmComponent } from '../modal-confirm/modal-confirm.component';
import {ButtonTypeEnum, IspColoursEnum} from '@i-supplier/angular-shared-module';
import {faChevronCircleLeft, faChevronCircleRight} from '@fortawesome/pro-duotone-svg-icons';


@Component({
  selector: 'cgu-modal',
  templateUrl: './cgu-modal.component.html',
  styleUrls: ['./cgu-modal.component.scss']
})
export class CguModalComponent implements OnDestroy {
  protected readonly ButtonTypeEnum = ButtonTypeEnum;
  protected readonly IspColoursEnum = IspColoursEnum;
  protected readonly faChevronCircleLeft = faChevronCircleLeft;
  protected readonly faChevronCircleRight = faChevronCircleRight;
  cguToBeValidated?: boolean;
  source?: any;
  subscriptions: Subscription[] = [];
  numPage: number;
  totalPages: number;

  constructor(
    public modalRef: BsModalRef,
    private translateService: TranslateService,
    private modalService: BsModalService,
    private authService: AuthService
  ) {
    this.numPage = 1;
    this.getCgu();
  }

  afterLoadComplete(pdfData: any) {
    this.totalPages = pdfData.numPages;
  }

  increment() {this.numPage++; }
  decrement() {this.numPage--; }

  refuseCgu() {
    let modalRefConfirm: BsModalRef<ModalConfirmComponent>;
    const initialState = {
      message: this.translateService.instant('app.cgu-confirm-refuse'),
      blueStyle: true
    };
    modalRefConfirm = this.modalService.show(ModalConfirmComponent, {class: 'confirm-modal' , initialState, backdrop: true});

    this.subscriptions.push(modalRefConfirm.content.actionConfirmed.subscribe({
      next: () => setTimeout(() => {
        this.modalRef.hide();
        this.authService.logout();
        }, 500)
    }));
  }

  acceptCgu() {
    this.authService.acceptCgu().subscribe({next: () => this.modalRef.hide()});
  }

  getCgu() {
    this.authService.getCgu().subscribe({next: (res: any) => this.source = URL.createObjectURL(res)});
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(value => value.unsubscribe());
  }
}
